import React, { FC } from 'react';

const TextHeader: FC<{ title: string; subtitle?: string }> = ({
    title,
    subtitle,
}) => (
    <div className="text-center">
        {title && (
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                {title}
            </p>
        )}
        {subtitle && (
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                {subtitle}
            </p>
        )}
    </div>
);

export default TextHeader;
